import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import Badge from '@shared/ui/badges/Badge';
import { BodySmall, Title } from '@src/components/styled/Typography';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-light/xmark-light.svg';
import { ReactComponent as CheckmarkIconComponent } from '@icons/wolfkit-light/checkmark-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import AvailabilityBadge from './AvailabiltyBadge';
const PlanListContainer = styled(ContainerRow)((props) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
}));
const CheckmarkIcon = styled(CheckmarkIconComponent)(props => ({
    path: {
        stroke: props.theme.palette.text.disabled,
    },
}));
const defineActiveStyles = ({ theme, active, isPlanDescription, }) => {
    if (active) {
        return {
            backgroundColor: theme.customColors.shades.primaryLight,
            boxShadow: theme.customColors.switcher.boxShadowActive,
            border: '2px solid trasparent',
        };
    }
    if (isPlanDescription) {
        return {
            backgroundColor: '#F8F9FA',
            boxShadow: 'unset',
            border: '2px solid #F8F9FA',
        };
    }
    return {
        backgroundColor: 'transparent',
        boxShadow: 'unset',
        border: '2px solid transparent',
    };
};
const InfoContainer = styled(PlanListContainer)(props => (Object.assign({ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: props.flex, borderRadius: 12, gap: 0 }, defineActiveStyles({
    theme: props.theme,
    active: props.active,
    isPlanDescription: props.isPlanDescription,
}))));
const PlanTextContainer = styled.div(props => ({
    padding: `${props.theme.spacing_sizes.l}px 0`,
    width: '100%',
    height: 80,
    borderBottom: '1px solid #EFF1F3',
}));
const PlanTitle = styled(Title)(props => ({
    fontSize: 30,
    lineHeight: '36px',
    textAlign: 'center',
    fontWeight: 600,
    position: 'relative',
    marginBottom: props.theme.spacing_sizes.xs * 0.75,
}));
const PlanDescription = styled(BodySmall)(props => ({
    height: 40,
    fontSize: 16,
    color: props.theme.palette.text.secondary,
    padding: `0 ${props.theme.spacing_sizes.xs * 4.5}px`,
    lineHeight: '19px',
    textAlign: 'center',
}));
const defineInfoCellStyles = ({ theme, isFirstElement, isPlanDescription, }) => {
    if (isFirstElement && isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.25}px 0`,
        };
    }
    if (isPlanDescription) {
        return {
            padding: `${theme.spacing_sizes.xs * 1.5}px 0`,
        };
    }
    return {
        padding: `${theme.spacing_sizes.xm}px 0`,
    };
};
const InfoCell = styled(ContainerColumn, {
    shouldForwardProp: propName => propName !== 'align' &&
        propName !== 'hasBorder' && propName !== 'isPlanDescription' &&
        propName !== 'isFirstElement',
})(props => (Object.assign({ alignItems: props.align || 'unset', height: 'auto', borderBottom: props.hasBorder ? `1px solid ${props.theme.palette.primary.light}` : 'unset', boxSizing: 'border-box' }, defineInfoCellStyles({
    theme: props.theme,
    isFirstElement: props.isFirstElement,
    isPlanDescription: props.isPlanDescription,
}))));
const FeeCell = styled(InfoCell)(props => ({
    fontSize: `${props.theme.baseSize * 4}px`,
    fontWeight: 600,
}));
const LimitsNumber = styled.span(() => ({
    fontWeight: 600,
}));
const LimitsText = styled.span(() => ({
    fontSize: 14,
}));
const ButtonContainer = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.s,
    boxSizing: 'border-box',
    alignItems: 'center',
    marginBottom: props.theme.spacing_sizes.l,
}));
const BadgeContainer = styled.span(props => ({
    position: 'absolute',
    top: 4,
    color: props.theme.palette.warning.main,
    backgroundColor: props.theme.palette.warning.light,
    padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.xs * 1.25}px`,
    fontSize: 14,
    marginLeft: 14,
    borderRadius: 6,
    boxSizing: 'border-box',
    lineHeight: '20px',
}));
const RecommendedBadge = () => {
    const { t } = useTranslation();
    return (_jsx(BadgeContainer, { children: t('settings.tabs.subscription.subscription_plan.recommended_badge') }));
};
const renderValue = (value) => (typeof value === 'boolean' ?
    _jsx(AvailabilityBadge, { isAvailable: value }) :
    value);
const SubscriptionPlanInfo = ({ plan, flex = 1, isDescriptionContainer = undefined, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const getFee = () => {
        if (plan.isFree)
            return t('settings.tabs.subscription.subscription_plan.free');
        if (plan.currency && plan.fee)
            return `${plan.currency || ''} ${plan.fee || ''} / month`;
        return '';
    };
    const getButtons = () => {
        if (isDescriptionContainer) {
            if (plan.current) {
                return (_jsxs(ButtonContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', startIcon: CheckmarkIcon, startIconProps: {}, children: t('settings.tabs.subscription.current_plan') }), _jsx(Button, { variant: 'plain', color: 'primary', size: 'small', startIcon: XMarkIcon, fullWidth: true, children: "Cancel plan" })] }));
            }
            if (!plan.isAvailable) {
                return (_jsx(Badge, { variant: 'tinted', style: {
                        backgroundColor: theme.palette.primary.light,
                        fontSize: 14,
                    }, children: t('settings.tabs.subscription.subscription_plan.coming_soon') }));
            }
            return (_jsx(ButtonContainer, { children: _jsx(Button, { variant: 'tinted', color: 'primary', size: 'medium', children: t('settings.tabs.subscription.subscription_plan.select_plan_btn') }) }));
        }
        return '';
    };
    return (_jsxs(InfoContainer, { flex: flex, active: plan.current, isPlanDescription: isDescriptionContainer, children: [_jsxs(PlanTextContainer, { children: [_jsx(PlanTitle, { children: plan.isRecommended ? (_jsxs(_Fragment, { children: [plan.title, _jsx(RecommendedBadge, {})] })) : plan.title }), _jsx(PlanDescription, { children: plan.description })] }), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isFirstElement: true, isPlanDescription: isDescriptionContainer, hasBorder: true, children: isDescriptionContainer ? (_jsxs(_Fragment, { children: [_jsx(LimitsNumber, { children: plan.options.limits }), _jsx(LimitsText, { children: t('settings.tabs.subscription.subscription_plan.per_exchange') })] })) :
                    plan.options.limits }, 'limits'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.spotCopy) }, 'spot-copy'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.margin) }, 'margin'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: renderValue(plan.options.futures) }, 'futures'), _jsx(InfoCell, { align: isDescriptionContainer ? 'center' : 'start', isPlanDescription: isDescriptionContainer, hasBorder: true, children: plan.options.support }, 'support'), _jsx(FeeCell, { align: isDescriptionContainer ? 'center' : 'start', children: getFee() }), getButtons()] }));
};
const SubscriptionPlanList = ({ plansList }) => {
    const { t } = useTranslation();
    const planDescriptionColumn = {
        title: '',
        description: '',
        options: {
            limits: t('settings.tabs.subscription.subscription_plan.copy_trading_limit'),
            spotCopy: t('settings.tabs.subscription.subscription_plan.spot_copy'),
            margin: t('settings.tabs.subscription.subscription_plan.margin'),
            futures: t('settings.tabs.subscription.subscription_plan.futures'),
            support: t('settings.tabs.subscription.subscription_plan.support'),
        },
        id: 'INFO',
    };
    return (_jsxs(PlanListContainer, { children: [_jsx(SubscriptionPlanInfo, { plan: planDescriptionColumn, flex: 1 }, planDescriptionColumn.id), plansList.map((plan) => (_jsx(SubscriptionPlanInfo, { plan: plan, flex: 2, isDescriptionContainer: true }, plan.id)))] }));
};
export default SubscriptionPlanList;
